import React from 'react';
import {Box, Button, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";

const ExistingBookingModal = ({data,isOpen, setOpen, sendReductedCar, carInfo}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setOpen && setOpen(false)
    }

    const handleSend = async (carInfo) =>{
        const sendingData = {
            id: carInfo?.id,
            is_active: carInfo?.is_active,
            is_always_active: carInfo?.is_always_active,
            confirm_update: true
        }
        try {
            await sendReductedCar(sendingData)
        } catch (e) {
            throw e
        } finally {
            handleClose()
        }


    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" component="span">
                        {data?.message}
                    </Typography>
                    <Box id="modal-modal-description" sx={{ mt: 2 }}>
                        {data?.bookings?.map((booking, index)=>(
                            <Box key={index} sx={{display:'flex', flexDirection:'column', gap: '5px'}}>
                                <Typography component={'span'} style={{marginBottom:'10px'}}>Существуют текущие брони, вы уверены что хотите деактивировать?</Typography>
                                <Typography component={'span'}>{index+1}. {booking?.organizer?.full_name} -{new Date(booking?.start_date_time).toLocaleDateString('ru')}</Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{marginTop:"10px", display: 'flex', gap: '5px', justifyContent:'flex-end'}}>
                        <Button variant="contained" onClick={()=>handleSend(carInfo)}>OK</Button>
                        <Button variant="outlined" onClick={handleClose}>Отмена</Button>
                    </Box>
                </Box>
            </Modal>
        </>

    );
};

export default ExistingBookingModal;