// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-car-wrapper{
    padding: 20px;
}
.edit-add-form{
    display: flex;
    flex-direction: column;
    gap: 15px;

}
.form-label{
    margin-bottom: 5px;
}

.edit-car-input{
    min-width: 100px;
    padding: 10px;
    box-sizing: content-box;
}

.schedule-row{
    display: flex;
    gap: 10px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/EditCar/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;AAEb;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".edit-car-wrapper{\n    padding: 20px;\n}\n.edit-add-form{\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n\n}\n.form-label{\n    margin-bottom: 5px;\n}\n\n.edit-car-input{\n    min-width: 100px;\n    padding: 10px;\n    box-sizing: content-box;\n}\n\n.schedule-row{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
