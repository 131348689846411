import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import {ALL_CARS, CREATE_NEW_CAR, DELETE_CAR, EDIT_CAR, REDUCT_OLD_CARS} from '../../api';
import TopBar from "../../Layout/TopHeader";
import {Box, Button, Modal} from "@mui/material";
import ExistingBookingModal from "../../Components/ExistingBookingModal";
import {useNavigate} from "react-router-dom";

const Main = () => {
    // const {availibleCars} = useSelector(s => s.cars)
    const [availibleCars, setAvailibleCars] = useState([])
    const [reductCar, setReductCar] = useState({})
    const [assureDel, setAssureDel] = useState({delete:false, car: {}})
    const [isOpen, setOpen] = useState(false)
    const [existingBooking, setExistingBooking] = useState({})
    const [bookingData, setBookingData] = useState()
    const navigate = useNavigate()

    const changeCarsTable = () => {
        ALL_CARS()
        .then(res =>{
            setAvailibleCars(res.data.data)
        })
        .catch(err => console.log(err))
    }
    useEffect(() =>{
        changeCarsTable()
    },[])
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          margin: 2,
        },
      }));


    const sendReductedCar = (data) => {
        setBookingData(data)
        EDIT_CAR(data.id, data)
        .then(({data} )=>{
            if (data?.message){
                setExistingBooking(data)
                setOpen(true)
            } else{
                console.log('success', data)
            }
            changeCarsTable()
            setReductCar({})
        })
        .catch(err => console.log(err))
    }

    const deleteCar = () => {
        DELETE_CAR(assureDel.car.id)
        .then(({data} )=>{
            changeCarsTable();
            setAssureDel({delete:false, car: {}})
        })
        .catch(err => console.log(err))
    }



    return (
        <>
            <div className='home details'>
                {
                    assureDel.delete && <>
                        <div className='home__myCars-popup'>
                            Вы уверены что хотите удалить  {assureDel.car.name}?

                            <div className='home__carsSetting-save'>
                                <Button variant="outlined" color="error" onClick={deleteCar}>Удалить</Button>
                                <Button onClick={() => setAssureDel({delete:false, car: {}})} variant="outlined" type="button">Отменить</Button>
                            </div>
                        </div>
                        <div className="detail__overlay" onClick={() => setAssureDel({delete:false, car: {}})}/>
                    </>
                }

                <div className="recentOrders">
                    <TopBar pageText={'Машины'}/>

                    <button onClick={()=> navigate('/add-car')} className='card button cardHeader__add' style={{marginTop:'20px'}}>
                        Добавить машину
                    </button>

                    <div style={{overflow:'auto'}}>
                        <table style={{width:'100%'}}>
                            <thead>
                            <tr>
                                <td>Название</td>
                                <td>Активна каждый день</td>
                                <td>Статус</td>
                                <td>Действие</td>
                            </tr>
                            </thead>
                            <thead>
                            {
                                availibleCars.map(car => (
                                    <tr key={car.id}>

                                        <td className='home__carsSetting'> {car.name}</td>

                                        <td className='home__carsSetting'>
                                            <FormControlLabel onChange={(e) =>{sendReductedCar({...car, is_always_active: e.target.checked})}}
                                                              control={<Android12Switch checked={car.is_always_active}/>}
                                                              label={car.is_always_active ?"да" : 'нет'}
                                            />
                                        </td>

                                        <td style={{textAlign:'center'}}>
                                            <FormControlLabel onChange={(e) =>{sendReductedCar({...car, is_active: e.target.checked})}}
                                                              control={<Android12Switch checked={car.is_active}/>}
                                                              label={car.is_active ?"активна" : 'не активна'}
                                            />
                                        </td>
                                        <td className='home__carsSetting-btns' >
                                            <img onClick={() => navigate(`/edit-car/${car.id}`)} className='recentOrders__eddit' width={30} src="https://img.icons8.com/ios/50/null/create-new.png"/>
                                            <img onClick={() => setAssureDel({delete:true ,car})} style={{cursor:'pointer'}}  width={30} src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/null/external-delete-miscellaneous-kiranshastry-lineal-kiranshastry.png"/>

                                        </td>
                                    </tr>
                                ))
                            }
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <ExistingBookingModal setOpen={setOpen} isOpen={isOpen} data={existingBooking} sendReductedCar={sendReductedCar} carInfo={bookingData}/>
        </>

    );
};

export default Main;