import React, {useState} from 'react';
import './style.css'
import {
    Alert,
    Button,
    Card,
    Checkbox,
    FormControl,
    IconButton,
    InputAdornment, InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import logo from '../../assets/baylyk-logos.png'
import FormControlLabel from "@mui/material/FormControlLabel";
import {useForm} from "react-hook-form";
import {LOGIN} from "../../api";
import {useNavigate} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {home_address, setStorageData} from "../../helpers/helpers";
import {sighnInSuccess} from "../../redux/reducers/authSlice";
import {useDispatch} from "react-redux";
const Login = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [isError, setError] = useState(false);
    const onSubmit = async (data)=>{
        setLoading(true)
        try{
            const res =  await LOGIN(data)
            const { is_superuser, username, access, refresh , full_name} = res.data.data;
            setStorageData('user', { isSuperUser: is_superuser, user: username, full_name: full_name});
            setStorageData('token', access);
            setStorageData('refresh', refresh);
            dispatch(sighnInSuccess({user: {isSuperUser: is_superuser, user: username, full_name: full_name}, token: access}))
            navigate('/')
            return { user: { isSuperUser: is_superuser, user: username }, token: access };
        } catch (e) {
            setError(true)
            throw e
        } finally {
            setLoading(false)
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <>
            <div className={'login-page'}>
                <a href={home_address}>
                    <img src={logo} style={{width:'200px'}}/>
                </a>

                <Card variant="outlined" style={{borderRadius: '6px' , padding: '40px'}} sx={{ boxShadow: 1 }}>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <FormControl style={{width: '350px'}} >
                            <TextField
                                label="Логин"
                                variant="outlined"
                                size="small"
                                autoComplete="username"
                                {...register("username" , { required: true })}
                                error={!!errors.username}
                                helperText={errors.username && "Логин обязателен"}
                                style={{marginBottom:'20px'}}/>
                            <FormControl>
                                <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    label="Пароль"
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    {...register("password" , { required: true })}
                                    error={!!errors.password}
                                    helpertext={errors.password && "Пароль обязателен"}
                                    autoComplete="current-password"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    size="small"   />
                                <p className={'error-msg'}>{errors.password && "Пароль обязателен"}</p>
                            </FormControl>

                            <FormControlLabel control={<Checkbox  />} label="Запомнить пароль" />
                            <Button style={{marginTop:'20px'}} variant="contained" type="submit" >{isLoading ? '' : 'Войти'}</Button>
                        </FormControl>
                    </form>
                    {isError && <Alert severity="error" style={{marginTop:'15px', width:'350px'}}>Не найдено активной учетной записи с указанными данными</Alert>}
                </Card>
            </div>
        </>

    );
};

export default Login;