import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {SHOW_BOOKING_DETAILS} from '../../api';
import DetailPopup from '../../Components/DetailPopup';
import {fetchCarBookings} from "../../redux/reducers/bookingSlice";
import CarTableColumn from "../../Components/BookingColumn";
import {fetchActiveCars} from "../../redux/reducers/carSlise";
import {Box, Button, Grid} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TopBar from "../../Layout/TopHeader";

const CarTable = () => {
    const activeCars = useSelector(state => state.cars.availibleCars);
    const {bookingData, carId, isActive} = useSelector(state => state.carBookings)

    const startTime = ['08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']
    const [week, setWeek] = useState(1)
    const [car, setCar] = useState( '')
    const [plan, setPlan] = useState({monday: [],tuesday: [],wednesday: [],thursday: [],friday: [],saturday: [],})
    const [details, setDetails] = useState({})
    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            await dispatch(fetchActiveCars());
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchData();
        }, 1000);

        return () => clearTimeout(timer);
    }, [dispatch]);

    const findDate = (day = 1) =>{
        const today = new Date();
        const first = today.getDate() - today.getDay() + day;
        const monday = new Date(today.setDate(first));

        const year = monday.getFullYear();
        const month = String(monday.getMonth() + 1).padStart(2, '0');
        const dayOfMonth = String(monday.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${dayOfMonth}`;
        // console.log(monday);
        return {day:monday?.getDate(), month: monday.toLocaleString('ru', { month: "short" }), fullDate: formattedDate}
    };

    let [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday] = [findDate(week), findDate(week+1), findDate(week+2), findDate(week+3), findDate(week+4),findDate(week+5)]

    const changeCarHandler = (car) =>{
        setCar(car)
        changeCarTable(car,'')

    }

    useEffect(() => {
        try{
            if (activeCars?.length > 0) {
                dispatch(fetchCarBookings({
                    id: car === '' ? activeCars[0]?.id : car ,
                    is_active: '',
                    start_date_time: Monday.fullDate,
                    end_date_time: Saturday.fullDate}));
            }
        } catch (e) {
            throw e
        }

    }, [dispatch, activeCars, car, week]);
    const changeCarTable = (car, active) =>{
        dispatch(fetchCarBookings({
            id:car,
            is_active: active,
            start_date_time: Monday.fullDate,
            end_date_time: Saturday.fullDate
        } ))
    }

    useEffect(() =>{
        bookingData && setPlan({
            monday: [bookingData.reduce((acc, rec, idx) => +bookingData[idx].start_date_time?.slice(8, 10) === Monday?.day? [...acc, {...rec, color:idx+1}] : [...acc]  ,[])],
            tuesday: [bookingData.reduce((acc, rec, idx) => +bookingData[idx].start_date_time?.slice(8, 10) === Tuesday?.day? [...acc, {...rec, color:idx+1}] : [...acc]  ,[])],
            wednesday: [bookingData.reduce((acc, rec, idx) => +bookingData[idx].start_date_time?.slice(8, 10) === Wednesday?.day? [...acc, {...rec, color:idx+1}] : [...acc]  ,[])],
            thursday: [bookingData.reduce((acc, rec, idx) => +bookingData[idx].start_date_time?.slice(8, 10) === Thursday?.day? [...acc, {...rec, color:idx+1}] : [...acc]  ,[])],
            friday: [bookingData.reduce((acc, rec, idx) => +bookingData[idx].start_date_time?.slice(8, 10) === Friday?.day? [...acc, {...rec, color:idx+1}] : [...acc]  ,[])],
            saturday: [bookingData.reduce((acc, rec, idx) => +bookingData[idx].start_date_time?.slice(8, 10) === Saturday?.day? [...acc, {...rec, color:idx+1}] : [...acc]  ,[])],
        })
    },[ bookingData, week])
    const showBookingDetails = (id) =>{
        SHOW_BOOKING_DETAILS(id)
        .then(({data} )=> setDetails(data.data))
        .catch(err => console.log(err))
    }

    return (
        <div className='details'>
           {
            details?.id && <>
            <DetailPopup details={details}/>
            <div className="detail__overlay" onClick={() => setDetails({})}/>
            </>
           }
            <div className="recentOrders">
                <TopBar pageText={'Расписание'}/>

                <Box sx={{marginTop:'20px'}}>
                    <div className={'filter-options'}>
                        <select onChange={(e) => changeCarHandler(e.target.value)} autoFocus style={{borderRadius:'8px', padding: '10px', backgroundColor:'none'}}>
                            <option value="">Выберите машину</option>
                            {
                                activeCars?.map(car => (
                                    <option value={car.id} key={car.id}>{car.name}</option>
                                ))
                            }
                        </select>
                        <select onChange={(e) => changeCarTable(car, e.target.value)} defaultValue='' style={{borderRadius:'8px', padding: '10px'}}>
                            <option value="">Все бронирования</option>
                            <option value={'true'} >Активные  </option>
                            <option value={'false'} >Прошедшие  </option>
                        </select>
                    </div>
                </Box>
                 <br />

                <div className={'booking-section'}>
                    <div style={{display: 'flex', flexDirection:'column', justifyContent: "center", gap:'5px'}}>
                        <h4 style={{ cursor:'pointer', textAlign:'center'}} onClick={() => setWeek(1)} >
                            {Monday.month}
                        </h4>
                            <span>
                            <img style={{cursor:'pointer'}} onClick={() => setWeek(prev => prev - 7)} src="https://img.icons8.com/plumpy/24/null/chevron-left.png"/>
                            <h4 style={{display: 'inline-block' ,width: '90px', textAlign:'center'}}>
                                {Monday?.day} - {Saturday.day}
                            </h4>
                            <img style={{cursor:'pointer'}} onClick={() => setWeek(prev => prev + 7)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuklEQVR4nOWUPQrCQBQGJygGFCG19jaCFmpAsBAMiIXYCOKFPIhnsBY8i6WlNlaGhQ0EMT9k3wPFga+dYRd24Z/wgBBYAoG0vAWcgZfdDVhJBg4pebInsJYKXD4ERCPHjIBYpAc8tCMz4F4Q2fxEZF7iuiLtyBWouUYi4JSzrovcty95n7Gdywn8ArlZv6q8YT+7PHn4lfJ6iWsZ48BQU25YaMoNU005QBvYvslHUvKEJjAAJkBHWl6ZGJyWYGk0ZXamAAAAAElFTkSuQmCC"/>
                            {/*<img style={{cursor:'pointer'}} onClick={handleNextWeek} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuklEQVR4nOWUPQrCQBQGJygGFCG19jaCFmpAsBAMiIXYCOKFPIhnsBY8i6WlNlaGhQ0EMT9k3wPFga+dYRd24Z/wgBBYAoG0vAWcgZfdDVhJBg4pebInsJYKXD4ERCPHjIBYpAc8tCMz4F4Q2fxEZF7iuiLtyBWouUYi4JSzrovcty95n7Gdywn8ArlZv6q8YT+7PHn4lfJ6iWsZ48BQU25YaMoNU005QBvYvslHUvKEJjAAJkBHWl6ZGJyWYGk0ZXamAAAAAElFTkSuQmCC"/>*/}
                        </span>
                            <span style={{display: 'inline-block' ,width: '110px'}}>
                        </span>
                    </div>
                    <div>
                        <Link to={'/bookingForm'} className='card button cardHeader__add'>
                            Бронировать
                        </Link>
                    </div>

                </div>

                <div  className='carTable'>
                    <div className='carTable__col'>
                        <p className='carTable__box'></p>
                        {
                        startTime.map(t=>(
                            <p key={t} className='carTable__box '>{t}</p>
                        ))
                       }
                    </div>

                    <CarTableColumn week={'Пн'} day={Monday.day} month={Monday.month} startTime={startTime} plan={plan.monday[0]} showBookingDetails={showBookingDetails} count={1}/>
                    <CarTableColumn week={'Вт'} day={Tuesday.day} month={Tuesday.month} startTime={startTime} plan={plan.tuesday[0]} showBookingDetails={showBookingDetails} count={2}/>
                    <CarTableColumn week={'Ср'} day={Wednesday.day} month={Wednesday.month} startTime={startTime} plan={plan.wednesday[0]} showBookingDetails={showBookingDetails} count={3}/>
                    <CarTableColumn week={'Чт'} day={Thursday.day} month={Thursday.month} startTime={startTime} plan={plan.thursday[0]} showBookingDetails={showBookingDetails} count={4}/>
                    <CarTableColumn week={'Пт'} day={Friday.day} month={Friday.month} startTime={startTime} plan={plan.friday[0]} showBookingDetails={showBookingDetails} count={5}/>
                    <CarTableColumn week={'Сб'} day={Saturday.day} month={Saturday.month} startTime={startTime} plan={plan.saturday[0]} showBookingDetails={showBookingDetails} count={6}/>
                </div>
            </div>
          
        </div>
        
    );
};

export default CarTable;