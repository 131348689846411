import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { AUTOLOGIN, CARBOOKING_LOGIN, REFRESH_TOKEN } from '../../api/index';
import { getStorageData, parseJwt, setStorageData } from '../../helpers/helpers';

//Action


const initialState = {
    token: null,
    isAuthorised: false,
    user: {},
    myBookings: [],
    loading: false,
    message: '',
    error: '',
    freshTokenPromise: null,
}

export const autoLogin = createAsyncThunk(
    'auth/autoLogin',
    async (payload, { dispatch, rejectWithValue }) => {
        const response = await AUTOLOGIN(payload);
        console.log(response)
        const { access } = response.data;
        setStorageData('token', access);
        // setStorageData('refresh', refresh);
        const userResponse = await CARBOOKING_LOGIN(access);
        console.log(userResponse)
        const { is_superuser, username, full_name , refresh} = userResponse.data.data;
        setStorageData('user', { isSuperUser: is_superuser, user: username , full_name: full_name});
        setStorageData('token', userResponse.data.data.access);
        setStorageData('refresh', refresh)
        dispatch(sighnInSuccess({user: {isSuperUser: is_superuser, user: username, full_name: full_name}, token: userResponse.data.data.access}))
        return { user: { isSuperUser: is_superuser, user: username, full_name:full_name }, token: userResponse.data.data.access };

    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        sighnInSuccess: (state, action) => {
            state.token = action.payload.access
            state.isAuthorised = true
            state.user =  action.payload.user
            state.loading = false
            state.error = null
        },
        sighnInFatal : (state, action) => {
            state.token = null
            state.isAuthorised = false
            state.error = action.payload
            state.loading = false
        },
        searchMyBookings: (state, action) =>{
            state.myBookings = action.payload
        },
        // autoLogin: (state, action) =>{
        //     state.loading = true
        //     console.log(action.payload);
        //      AUTOLOGIN(action.payload)
        //     .then(res =>{
        //         console.log('CARBOOKING_LOGIN');
        //         setStorageData('token', res.data.access);
        //         CARBOOKING_LOGIN(res.data.access)
        //         .then(response =>{
        //             console.log('autologin success', response);
        //             setStorageData('token', response.data.access);
        //             setStorageData('refresh', response.data.refresh);
        //             const {is_superuser, username, access, refresh} = response.data.data
        //             setStorageData('user', {isSuperUser: is_superuser, user: username});
        //             state.user = {isSuperUser: is_superuser, user: username}
        //         })
        //         .catch(error =>{
        //             console.log(error);
        //         })
        //
        //         if(localStorage.getItem('user')){
        //             let user = JSON.parse(localStorage.getItem('user'));
        //         }
        //     })
        //     .catch(err =>{
        //         console.log(err);
        //         state.error = err
        //     })
        //     state.loading = false
        //
        // },
        refreshToken: (state, action) => {
            console.log('need refreshToken');
            const freshTokenPromise = REFRESH_TOKEN({ refresh: getStorageData('refresh') })
            .then(res => {
                state.token = res.data.access
                setStorageData('token', res.data.access)
                console.log('refreshed =>', res.data);
                return res.data.access ? Promise.resolve(res.data.access) : Promise.reject({
                    message: 'could not refresh token'
                });
            })
            .catch(e => {
                console.log(e);
                // window.location = 'http://localhost:5001/'
                return Promise.reject(e);
            });
            return freshTokenPromise;
    
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(autoLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(autoLogin.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.isAuthorised = true;
            })
            .addCase(autoLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
})


export const {sighnInSuccess, sighnInFatal, refreshToken, searchMyBookings} = authSlice.actions
export default authSlice.reducer