export const parseJwt = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


export const getToken =()=>{
    let token = localStorage.getItem("token");
    if(token){
        token = token.slice(1, -1)
        return token
    }
    else return 'unauthorized'

}

export const getCreator =()=>{
    let userData = JSON.parse(localStorage.getItem("user"));
    return userData.name
}



export const getStorageData = (key) => {
    return JSON.parse(localStorage.getItem(key))
};

export const setStorageData = (key, data) => {
    if(key && data) {
       return  localStorage.setItem(key, JSON.stringify(data))
    }
};

export const generatePublicUrl = (fileName) => {
    return `http://localhost:4000/public/${fileName}`;
}

export const home_address = process.env.REACT_APP_PORTAL_URL;

export  const startTime = ['--:--','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']
