import TopBar from "../../Layout/TopHeader";
import './style.css'
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CREATE_NEW_CAR, EDIT_CAR, GET_CAR} from "../../api";
import {useForm} from "react-hook-form";
import Schedule from "./Schedule";
const EditAddCar = ()=>{
    const [period, setPeriod] = useState([{ id:0,start_date_time:'', end_date_time: ''}])
    const [activity, setActivity] = useState({is_always_active: '', is_active: ''})
    const {id} = useParams()
    const [open, setOpen] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm()
    const navigate = useNavigate()
    console.log(period)
    useEffect(() => {
        const getACar = async () => {
            try {
                const res = await GET_CAR(id);
                const { data  } = res.data
                reset(data);
                console.log(data)
                setValue("is_always_active", data?.is_always_active.toString())
                setValue("is_active", data?.is_active.toString())
                setActivity({is_active: data.is_active.toString(), is_always_active: data.is_always_active.toString()})
                setPeriod(data.activity_times)
            } catch (e) {
                throw e;
            }
        };

        if (id) {
            getACar();
        }
    }, [id]);

    const onSubmit = async (data) =>{
        const activeTimes = period.filter(p=> p.start_date_time && p.end_date_time)
        const submitForm = {
            name: data.name,
            is_active: data.is_active,
            is_always_active: data.is_always_active,
            activity_times: activeTimes
        }
        try {
            console.log(submitForm)
            id ? await EDIT_CAR(id, submitForm) : await CREATE_NEW_CAR(submitForm)
            setOpen(true)
        } catch (e) {
            throw new Error()
        }
    }
    return(
        <>
            {open &&
            <>
                <div className='home__myCars-popup'>
                    {`Машина ${id? 'изменена' : 'создана' } успешно`}
                    <div className='home__carsSetting-save'>
                        <Button variant="contained" sx={{marginY: '15px'}} onClick={() => navigate('/cars')} type="button"> ОК</Button>
                    </div>
                </div>
            </>
            }
            <div className='edit-car-wrapper'>
                <TopBar pageText={id ? 'Редактировать' : 'Добавить'}/>
                <form className='edit-add-form' onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <p className='form-label'>Название</p>
                        <input className='edit-car-input' {...register('name', {required: 'Это поле не может пустым',})}/>
                        <p style={{color:'red', fontSize:'12px'}}> {errors.name?.message}</p>
                    </div>

                    <div >
                        <p className='form-label'>Активна каждый день</p>
                        <select
                            {...register('is_always_active', {required: 'Это поле не может пустым',})}
                            onChange={(e)=> setActivity({...activity, is_always_active: e.target.value})}
                        >
                            <option value={''}>выберите активность</option>
                            <option value={'true'}>да</option>
                            <option value={'false'}>нет</option>
                        </select>
                        <p style={{color:'red', fontSize:'12px'}}> {errors.is_always_active?.message}</p>
                    </div>

                    <div >
                        <p className='form-label' >Статус</p>
                        <select
                            {...register('is_active', {required: 'Это поле не может пустым',})}
                            onChange={(e)=> setActivity({...activity, is_active: e.target.value})}
                        >
                            <option value={''}>выберите статус</option>
                            <option value={'true'}>активна</option>
                            <option value={'false'}>не активна</option>
                        </select>
                        <p style={{color:'red', fontSize:'12px'}}> {errors.is_active?.message}</p>
                    </div>

                    {activity.is_always_active === 'false' && activity.is_active === 'true' &&
                        <Schedule period={period} setPeriod={setPeriod} register={register}/>
                    }
                    <div style={{display:'flex', gap:'5px'}}>
                        <Button variant="contained" type='submit' >{`${id ? 'Изменить' : 'Создать'}`}</Button>
                        <Button variant="outlined" type="button" onClick={()=> navigate('/cars')}>Отменить</Button>
                    </div>

                </form>
            </div>
        </>

    )
}

export default EditAddCar