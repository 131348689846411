import React, { useEffect, useState } from "react";
import {AiFillHome} from "react-icons/ai";
import {MdDomainVerification} from "react-icons/md";
import {RiPoliceCarFill} from 'react-icons/ri'
import {GiCarKey} from 'react-icons/gi'
import { NavLink } from "react-router-dom";
import {home_address} from "../../helpers/helpers";
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import {useSelector} from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Box} from "@mui/material";


const Navigation = ({toggle, setToggle}) => {
  const [isAdmin, setIsAdmin] = useState(null)
    const {user} = useSelector(state => state.auth)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    user &&  setIsAdmin(user.isSuperUser)
  },[localStorage.getItem('user')])


    return (
        <>
            <div className='burger-menu' onClick={() => setToggle(prev=> !prev)}>
                <MenuIcon/>
                <Box sx={{display:'flex', gap:'5px', alignItems:'center'}} className={'user-name'}>
                    <span> {user.full_name}</span>
                    <AccountCircleIcon />
                </Box>
            </div>

            <nav className={`navigation ${toggle && 'active'}`}>
                <ul className="nav__list">
                    <li className="nav__list-item">
                        <a href={home_address}>
                            <span className="nav__link-icon icon"><AiFillHome className="icon"/></span>
                            <span className="nav__link-title title">Портал</span>
                        </a>

                    </li>
                    <li className={`nav__list-item `}>
                        <NavLink to={'/'}>
                            <span className="nav__link-icon icon"><MdDomainVerification className="icon"/></span>
                            <span className="nav__link-title title">Главная</span>
                        </NavLink>
                    </li>
                    <li  className={`nav__list-item `}>
                        <NavLink to={'/bookings'}>
                            <span className="nav__link-icon icon"><GiCarKey className="icon"/></span>
                            <span className="nav__link-title title">Мои бронирования</span>
                        </NavLink>
                    </li>
                    {
                        isAdmin ?
                            <li className={`nav__list-item `}>
                                <NavLink to={'/cars'}>
                                    <span className="nav__link-icon icon"><RiPoliceCarFill className="icon"/></span>

                                    <span className="nav__link-title title">Машины</span>
                                </NavLink>
                            </li>
                            : ''
                    }



                </ul>
                <div onClick={() => setToggle(prev=> !prev)} className='burger'><ClearIcon className="burger"/></div>

            </nav>
        </>

    );
};

export default Navigation;