import axios from 'axios'
import {getStorageData} from '../helpers/helpers';

const instance_portal = axios.create({
    baseURL: process.env.REACT_APP_PORTAL
})

const instance_booking = axios.create({
    baseURL: process.env.REACT_APP_BOOKING
})
// 'http://localhost:5000/portal/api/'


instance_booking.interceptors.request.use(async function (config) {
    const token = await getStorageData('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instance_booking.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401 && window.location.pathname !== '/login') {
            // Redirect to login page
            window.location.href = '/login'
        }
        return Promise.reject(error);
    }
);

    export const AUTOLOGIN = async (data) => {
    const response = await instance_portal.post(`verify_auto_token/`, {...data});
    return response;
};

export const REFRESH_TOKEN = async (refreshToken) => {
    const response = await instance_portal.post('token/refresh/', {...refreshToken});
    return response;
};

export const CARBOOKING_LOGIN = async (token) => {
    const response = await instance_booking.get('accounts/employees/autologin/');
    return response;
};


export const SEARCH_USER = async (search) => {
    return await instance_booking.get('accounts/employees/?search=' + search);
}


export const ACTIVE_CARS = async () => {
    return await instance_booking.get('cars/?is_active=true');
}
export const ALL_CARS = async () => {
    const response = await instance_booking.get('cars/?is_active=""');
    return response;
}

export const GET_CAR = async (id) => {
    return await instance_booking.get(`cars/${id}`);
}

export const MAKE_BOOKING = async (data) => {
    const response = await instance_booking.post('bookings/', {...data})
    return response;
}
export const DELETE_BOOKING = async (id) => {
    const response = await instance_booking.delete(`bookings/${id}/`)
    return response;
}
export const REDUCT_BOOKING = async (id, data) => {
    const response = await instance_booking.patch(`bookings/${id}/`, data)
    return response;
}



export const SHOW_BOOKING_DETAILS = async (id) => {
    const response = await instance_booking.get(`bookings/${id}`)
    return response;
}

export const LOOK_CAR_BOOKING = async (id, active, start_date_time ,end_date_time) => {
    return await instance_booking.get(`bookings/?is_active=${active}&car__id=${id}&start_date_time=${start_date_time}&end_date_time=${end_date_time}`);
}

export const LOOK__MY_BOOKINGS = async (name, car, start, end, status) =>{
    const response = await instance_booking.get(`bookings/?organizer__username=${name}&car__id=${car}&start_date_time=${start}&end_date_time=${end}&is_active=${status}`)
    return response;
}


export const EDIT_CAR = async (id, data) => {
    return await instance_booking.patch(`cars/${id}/`, {...data});
}

export const CREATE_NEW_CAR = async (data) => {
    return await instance_booking.post(`cars/`, {...data});
}

export const DELETE_CAR = async (id) =>{
    const response = await instance_booking.delete(`cars/${id}`)
    return response;
}

export const LOGIN = async (data)=>{
    return await instance_booking.post(`token/`, {...data});
}