import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { DELETE_BOOKING, MAKE_BOOKING, REDUCT_BOOKING, SEARCH_USER, SHOW_BOOKING_DETAILS } from '../../api/index';
import { refreshToken } from '../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { showResponse, showTimeErr } from '../../redux/reducers/carSlise';
import { useNavigate, useParams } from 'react-router-dom';
import {Box, Button} from "@mui/material";
import {fetchCarBookings} from "../../redux/reducers/bookingSlice";
import {startTime} from "../../helpers/helpers";
import Schedule from "../EditCar/Schedule";

const ReductMyBookings = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const [places, setPlaces] = useState([{id:0, placeName:'', time: ''}])
    const [correctPlace, setCorrectPlace] = useState('')
    const [employees, setEmployees] = useState([])
    const [correctEmployee,setCorrectEmployee] = useState('')
    const [driveTime, setDriveTime] = useState('T08:30')
    const [driveDay, setDriveDay] = useState('')
    const [needDriveDay, setNeedDriveDay] = useState('')
    const [lastPlace, setLastPlace] = useState('')
    const [carInfo, setCarInfo] = useState('')
    const [comment, setComment] = useState('')
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const [period, setPeriod] = useState([])
    const [showPeriod, setShowPeriod] = useState(false)
    const {
        reset,
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm()
    
    const {availibleCars, response, timeErr} = useSelector(s => s.cars)

    // "Этот автомобиль \"Ferrari F8 Tributo\" активен только сегодня c 08:30 до 17:30"
    const showBookingDetails = () =>{
        SHOW_BOOKING_DETAILS(id)
        .then(({data} )=>{
            setDriveDay(data.data.start_date_time.slice(0,10));
            setDriveTime(data.data.start_date_time.slice(10,16));
            setEmployees(data.data.employees)
            setComment(data.data.comment)
            setPlaces(data.data.addresses.map((place,i) => Object.assign({id:i, placeName: place.name, time: place.duration})))
            setCarInfo(data.data.car)
        })
        .catch(err => console.log(err))
    }
    
   useEffect(() => {
       reset()
    dispatch(showTimeErr(''))
    showBookingDetails()
   },[])


    useEffect(()=>{
        console.log(carInfo?.activity_times)
        if (carInfo?.activity_times?.length !== 0){
            setPeriod( carInfo?.activity_times)
            setShowPeriod(true)
        }else setShowPeriod(false)
    },[carInfo])
    const addPlace = () =>{
        if (places.at(-1)?.time && places?.at(-1).placeName.trim()) {
            setPlaces(prev => [...prev, {id: prev.at(-1).id + 1, placeName:'', time: ''}])
        } else {
            setCorrectPlace('Выберите место и срок')
        }
    }
    const delPlace = (id) =>{
        places.length > 1 &&
        setPlaces(prev => prev.filter(p => p.id !== id ))
        setCorrectPlace('')
    }
    const addEmployee = () =>{
        console.log(employees);
        if (!employees.length) return setEmployees([{id: 0, full_name: '', hisId:0}])
        if (employees.at(-1)?.id !== undefined && employees?.at(-1).full_name.trim()) {
            setEmployees(prev => [...prev, {id: prev.at(-1).id + 1, full_name: ''}])
        } else {
            setCorrectEmployee('внесите сотрудника')
        }
    }
    const reductEmployee = (id, full_name) => {
        const hisId = users.find(u => u.full_name === full_name)?.id;
        // console.log(hisId, name);\ console.log(employees);
        setEmployees(prev => prev.map(e => e.id == id ? {...e, id: hisId, full_name} : e))
        setCorrectEmployee('')
    }
    const reductPlace = (id, placeName, time) =>{
        setPlaces(prev => prev.map(p => p.id == id ? {...p, placeName, time} : p))
        setCorrectPlace('')
    }

    const searchHandler = (e) =>{
        SEARCH_USER(e.target.value)
        .then(res => {console.log(res.data); setUsers(res.data.data)})
        .catch(err =>{
            console.log(err);
            dispatch(refreshToken())
        })
    }

//    useEffect
    const totalM = () =>{
        const placesM = places.reduce((acc, rec) =>{
        return acc + +rec.time
        }, 0)
        return placesM
    }
    const totalTime = () =>{
        let hours = Math.floor(totalM() / 60)
        let minutes = totalM() % 60
        return `${hours}ч ${minutes}мин.`
    }

    const deleteBooking = () => {
        DELETE_BOOKING(id) 
        .then(res =>{ 
            setSuccess(true)
            dispatch(fetchCarBookings({id: availibleCars[0]?.id, is_active: true}));
            navigate('/bookings')
        })
        .catch(err =>{
            console.log(err.response.data.data);
             dispatch(showTimeErr(err.response.data.data.date_time[0]))
            })
    }

    const onSubmit = (data) =>{
        if( employees.length && !employees?.at(-1)?.full_name.trim()) return setCorrectEmployee('внесите нового сотрудника')

        if (!driveDay) return setNeedDriveDay('Не выбрана дата')
        
        if(!places.at(-1)?.time || !places?.at(-1).placeName.trim() ) return setCorrectPlace('Добавьте пункт и срок')

        const extraMilS = totalM() * 60000
        const end_date = new Date(new Date(driveDay+driveTime).getTime() + extraMilS)
        const end_date_time = `${end_date.getFullYear()}-${end_date.getMonth()+1}-${end_date.getDate()}T${end_date.getHours()}:${end_date.getUTCMinutes() || '0'+ end_date.getUTCMinutes() }`

        REDUCT_BOOKING( id, {...data,
            start_date_time: driveDay+driveTime , end_date_time,
             employees: employees.map(em => em.id),
             addresses: lastPlace?.placeName ? places.concat(lastPlace).map(p => Object.assign({name: p.placeName, duration:p.time})) :places.map(p => Object.assign({name: p.placeName, duration:p.time}))
            })
        .then(res =>{ 
            console.log(res.data);
            dispatch(fetchCarBookings({id: data.car, is_active: true}));
            setSuccess('success')
        })
        .catch(err =>{
            console.log(err.response.data);
             dispatch(showTimeErr(err.response.data.data.date_time[0]))
        })
    }

    const getCarIdByName = (chosenCar) => {
        const car = availibleCars.find((car) => car.name === chosenCar.name);
        return car ? car.id : null;
    };

    const handleChooseCar = (e) => {
        const carId = parseInt(e.target.value);
        const chosenCar = availibleCars.find((car) => car.id === carId);
        setCarInfo(chosenCar);
        if (chosenCar.activity_times.length !== 0){
            setPeriod(chosenCar.activity_times)
            setShowPeriod(true)
        } else setShowPeriod(false)
    };
    
    return (
        <div className='details'>
            {/* <h1 className='home__title' style={{textAlign: 'center'}}>Бронирование машин</h1> */}
            {
                success == 'delete' ? <>
                <div className='home__myCars-popup'>
                    <h3 style={{textAlign:'center', width:'100%',fontSize:'18px'}}>Вы уверены что хотите удалить?</h3>

                    <div className='home__carsSetting-save'>
                        <Button variant="outlined" color="error" onClick={deleteBooking}>Удалить</Button>
                        <Button onClick={() => navigate('/bookings')} variant="outlined" type="button">Отменить</Button>
                    </div> 
                </div>
                <div className="detail__overlay" onClick={() => setSuccess(false)}/>
                </> 
                : 
                success == 'success' ?
                <>
                    <div className='home__myCars-popup'>
                        Бронь изменена успешно

                        <div className='home__carsSetting-save'>
                            <Button variant="contained" sx={{marginY: '15px'}} onClick={() => navigate('/')} type="button"> На главную</Button>
                        </div>
                    </div>
                    <div className="detail__overlay" onClick={() => setSuccess(false)}/>
                </>
                : ''
            }
            <form className="recentOrders" onSubmit={handleSubmit(onSubmit)}>
                <div  style={{display:'flex', gap:"60px"}}>
                    <div>
                        <div className="cardHeader">
                            <h2 className=''>Редактировать</h2> <br /> <br />  <br />
                        </div>
                        <div>
                            <h3>Машина</h3>
                            <select {...register('car')} autoFocus value={getCarIdByName(carInfo) || ''}
                                    onChange={(e)=>handleChooseCar(e)}>
                                {
                                    availibleCars?.map(car => (
                                        <option value={car.id} key={car.id}>{car.name}</option>
                                    ))
                                }
                            </select>

                        </div>
                        <br />
                        <div>
                            <h3>Начало</h3>

                            <input onChange={(e)=>{setNeedDriveDay('') ;setDriveDay(e.target.value)}} value={driveDay} type="date" placeholder='дата'/>
                            <select value={driveTime} onChange={e => setDriveTime(e.target.value)}>
                                {
                                    startTime.map((t, idx) => (
                                        <option key={idx} value={'T'+t}>{t}</option>
                                    ))
                                }


                            </select>
                            <br />
                            <h3 className="form__message">
                                {needDriveDay}
                            </h3>
                            <br />
                            <h3 className="form__message">
                                {timeErr}
                            </h3>

                        </div>
                        <div>
                            <Button variant="contained" sx={{marginY: '15px'}} onClick={addEmployee} type="button"> Добавить сотрудника</Button>
                            <br />
                            {
                                employees?.map((el,idx) =>(
                                    <span key={idx} style={{marginRight:'20px'}}>
                        <input type="text" value={el.full_name}  onChange={(e) => {searchHandler(e);reductEmployee(el.id ,e.target.value); console.log(e.target);}} placeholder='имя'list='users'/>
                        <button className='recentOrders__addBtn-close inside' onClick={() =>{setCorrectEmployee('') ;setEmployees(prev => prev.filter(item => item.id !== el.id))}} type="button">X</button>
                     </span>

                                ))
                            }
                            <h3 className="form__message">
                                {correctEmployee}
                            </h3>
                            <datalist id='users'>
                                {
                                    users?.map(user => (
                                        <option key={user.id} value={user.full_name}>{user.username}</option>
                                    ))
                                }
                            </datalist>
                        </div>
                        <div>
                            <br />
                            <Button variant="contained" sx={{marginY: '15px'}} onClick={addPlace} type="button"> Добавить точку</Button>
                            {/*<br /> <br /> <h3><input className='recentOrders__addBtn' onClick={addPlace} type="button" value="+ Добавить точку"/></h3> <br />*/}
                            {
                                places?.map((el, id) => (
                                    <div className='recentOrders__newPlace' key={el.id}>
                                        <br />
                                        <input onChange={(e) => reductPlace(el.id, e.target.value, el.time)} value={el.placeName} type="text" placeholder='название' />
                                        <select onChange={(e) => reductPlace(el.id, el.placeName, e.target.value)} value={el.time}>
                                            <option value="" disabled>длительность</option>
                                            <option value="30">30 мин</option>
                                            <option value="60">1 час</option>
                                            <option value="90">1ч. 30мин.</option>
                                            <option value="90">2 часа</option>
                                            <option value="90">2ч. 30мин.</option>
                                            <option value="90">3 часа</option>
                                        </select>
                                        <button className='recentOrders__addBtn-close' onClick={() => delPlace(el.id)}>X</button>
                                        {/* <input className='recentOrders__addBtn' onClick={() => delPlace(el.id)} type="button" value="удалить"/> */}
                                    </div>
                                ))
                            }
                            <div className="form-group">
                    <textarea {...register('comment')}
                              className="form-control" id="exampleFormControlTextarea1"
                              onChange={(e)=>{setComment(e.target.value)}} value={comment ||''}
                              rows="4" placeholder={'комментарии'} style={{width:'200px'}}></textarea>
                            </div>

                            <h3 className='form__message'>{correctPlace}</h3>
                            {/* <h3>{response}</h3> */}
                        </div>
                        <br />
                        <Box sx={{display:'flex', gap: '5px', marginTop:'15px'}}>
                            <Button variant="outlined" color="error" onClick={() =>setSuccess('delete')}>Удалить</Button>
                            <Button onClick={() => navigate('/bookings')} variant="outlined" type="button">Отменить</Button>
                            <Button variant="contained" type="submit">Изменить</Button>
                        </Box>
                    </div>
                    {showPeriod &&
                        <div style={{marginTop:'60px'}}>
                            <Schedule period={period} setPeriod={setPeriod} register={register} readOnly/>
                        </div>}
                </div>


                <br /> <br />
                <div>
                    <div>Количество адресов: {places.length} </div> <div>Длительность - {totalTime()}</div>
                </div>
            
            </form>
        </div>
        
        
    );
};

export default ReductMyBookings;