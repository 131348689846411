import React from 'react';

const TimeSlot = ({ day, month, startTime, plan, showBookingDetails,count }) => {
    const same = plan?.find(i => startTime < i.end_date_time?.slice(11, 16) && startTime >= i.start_date_time?.slice(11, 16) && count === new Date(i.start_date_time).getDay());
    const planStart = plan?.find(i => startTime === same?.start_date_time?.slice(11, 16));

    return (
        <div
            onClick={() => same ? showBookingDetails(same?.id) : ''}
            className={`carTable__box carTable__info ${same && same.is_active && 'progress' || same && 'past'}`}
            style={{ borderTop: planStart && '1px solid white', filter: same?.color % 2 === 0 && 'saturate(167%)' , cursor:'pointer'}}>
            {planStart && same?.organizer?.split(' ')[0] || ''}
            <p className='carTable__info-time'>{planStart && `${same?.start_date_time.slice(11, 16)} - ${same?.end_date_time.slice(11, 16)} `}</p>
        </div>
    );
};

const CarTableColumn = ({ day,week, month, startTime, plan, showBookingDetails, count }) => {
    return (
        <div className='carTable__col carTable__info'>
            <p className='carTable__box title'>{week} {day}</p>
            {startTime.map(t => (
                <TimeSlot key={t} day={day} month={month} startTime={t} plan={plan} showBookingDetails={showBookingDetails} count={count}/>
            ))}
        </div>
    );
};

export default CarTableColumn;
