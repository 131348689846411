// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/login_bg.c2309e98.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.error-msg{
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 4px 14px 0 14px;
    color: #d32f2f;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/style.css"],"names":[],"mappings":"AAAA;IACI,mDAAwD;IACxD,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;AAClB","sourcesContent":[".login-page{\n    background: url('../../../public/login_bg.c2309e98.jpg');\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    align-items: center;\n    justify-content: center;\n    margin: 0 auto;\n}\n\n.error-msg{\n    font-weight: 400;\n    font-size: 0.75rem;\n    line-height: 1.66;\n    letter-spacing: 0.03333em;\n    text-align: left;\n    margin: 4px 14px 0 14px;\n    color: #d32f2f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
