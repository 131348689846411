import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { MAKE_BOOKING, SEARCH_USER } from '../../api/index';
import { refreshToken } from '../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { showResponse, showTimeErr } from '../../redux/reducers/carSlise';
import { useNavigate } from 'react-router-dom';
import {Box, Button} from "@mui/material";
import {fetchCarBookings} from "../../redux/reducers/bookingSlice";
import {startTime} from "../../helpers/helpers";
import Schedule from "../EditCar/Schedule";

const BookingForm = () => {
    const dispatch = useDispatch()
    const [places, setPlaces] = useState([{id:0, placeName:'', time: ''}])
    const [correctPlace, setCorrectPlace] = useState('')
    const [employees, setEmployees] = useState([])
    const [correctEmployee,setCorrectEmployee] = useState('')
    const [chooseCar,setChooseCar] = useState('')
    const [driveTime, setDriveTime] = useState('T08:30')
    const [driveDay, setDriveDay] = useState('')
    const [needDriveDay, setNeedDriveDay] = useState('')
    const [lastPlace, setLastPlace] = useState({id:0, placeName:'Головной офис', time:'30'})
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const [period, setPeriod] = useState([])
    const [showPeriod, setShowPeriod] = useState(false)
    const {
        reset,
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm()
//   console.log(employees);
    
    const {availibleCars, response, timeErr} = useSelector(s => s.cars)

    // "Этот автомобиль \"Ferrari F8 Tributo\" активен только сегодня c 08:30 до 17:30"
    
   useEffect(() => {
    dispatch(showTimeErr(''))
   },[])
    console.log(places);

    const addPlace = () =>{
        if (places.at(-1)?.time && places?.at(-1).placeName.trim()) {
            setPlaces(prev => [...prev, {id: prev.at(-1).id + 1, placeName:'', time: ''}])
        } else {
            setCorrectPlace('Выберите место и срок')
        }
    }
    const delPlace = (id) =>{
        places.length > 1 &&
        setPlaces(prev => prev.filter(p => p.id !== id ))
        setCorrectPlace('')
        // console.log(places);
    }
    const addEmployee = () =>{
        console.log(employees);
        if (!employees.length) return setEmployees([{id: 0, name: '', hisId:0}])
        if (employees.at(-1)?.id !== undefined && employees?.at(-1).name.trim()) {
            setEmployees(prev => [...prev, {id: prev.at(-1).id + 1, name: ''}])
        } else {
            setCorrectEmployee('внесите сотрудника')
        }
    }
    const reductEmployee = (id, name) => {
        const hisId = users.find(u => u.full_name === name)?.id;
        // console.log(hisId, name);
        // console.log(employees);
        setEmployees(prev => prev.map(e => e.id == id ? {...e, hisId: hisId, name} : e))
        setCorrectEmployee('')
    }
    const reductPlace = (id, placeName, time) =>{
        setPlaces(prev => prev.map(p => p.id == id ? {...p, placeName, time} : p))
        setCorrectPlace('')
    }

    const searchHandler = (e) =>{
        SEARCH_USER(e.target.value)
        .then(res => {console.log(res.data); setUsers(res.data.data)})
        .catch(err =>{
            console.log(err);
            dispatch(refreshToken())
        })
    }

//    useEffect
    const totalM = () =>{
        const placesM = places.reduce((acc, rec) =>{
        return acc + +rec.time
        }, 0)
        return placesM + +lastPlace.time || placesM
    }
    const totalTime = () =>{
        let hours = Math.floor(totalM() / 60)
        let minutes = totalM() % 60
        return `${hours}ч ${minutes} мин.`
    }

    const onSubmit = (data) =>{
        console.log('lll', data.car)
        if( employees.length && !employees?.at(-1)?.name.trim()) return setCorrectEmployee('Внесите нового сотрудника')

        if (!driveDay) return setNeedDriveDay('Не выбрана дата')
        
        if(!places.at(-1)?.time || !places?.at(-1).placeName.trim() ) return setCorrectPlace('Добавьте пункт и срок')

        if (data.car === '') return setChooseCar('Выберите машину')

        console.log( places.map(p => Object.assign({name: p.placeName})));
        const extraMilS = totalM() * 60000
        const end_date = new Date(new Date(driveDay+driveTime).getTime() + extraMilS)
        const end_date_time = `${end_date.getFullYear()}-${end_date.getMonth()+1}-${end_date.getDate()}T${end_date.getHours()}:${end_date.getUTCMinutes()}` 
        // console.log(new Date(driveDay+driveTime)); console.log(end_date_time);
        console.log({...data,
            start_date_time: driveDay+driveTime ,end_date_time, 
            employees: employees.map(em => em.hisId), 
            addresses: lastPlace.placeName ? places.concat(lastPlace).map(p => Object.assign({name: p.placeName, duration:p.time})) :places.map(p => Object.assign({name: p.placeName, duration:p.time}))
        });

        MAKE_BOOKING({...data,
            start_date_time: driveDay+driveTime , end_date_time,
             employees: employees.map(em => em.hisId),
              addresses: lastPlace.placeName ? places.concat(lastPlace).map(p => Object.assign({name: p.placeName, duration:p.time})) :places.map(p => Object.assign({name: p.placeName, duration:p.time}))
            })
        .then(res =>{
            console.log(res.data);
            dispatch(fetchCarBookings({id: data.car, is_active: true}));
            setSuccess(true)
        })
        .catch(err =>{
            console.log(err.response.data.data);
             dispatch(showTimeErr(err.response.data.data.date_time[0]))
            })
    }

    const handleChooseCar = (id) =>{
        const chosenCarObj = availibleCars.find(car => car.id === parseInt(id))
        if (chosenCarObj.activity_times.length !== 0){
            setPeriod(chosenCarObj.activity_times)
            setShowPeriod(true)
        } else setShowPeriod(false)
    }
    
    return (
        <div className='details'>
            {/* <h1 className='home__title' style={{textAlign: 'center'}}>Бронирование машин</h1> */}

            {
                success && <>
                <div className='home__myCars-popup'>
                    На это время бронь прошла успешно

                    <div className='home__carsSetting-save'>
                        <Button variant="contained" sx={{marginY: '15px'}} onClick={() => navigate('/')} type="button"> На главную</Button>
                    </div> 
                </div>
                <div className="detail__overlay" onClick={() => setSuccess(false)}/>
                </>
            }
                <form className="recentOrders" onSubmit={handleSubmit(onSubmit)}>
                    <div style={{display:'flex', gap:"60px"}}>
                        <div>
                            <div className="cardHeader">
                                <h2 className=''>Создать бронь</h2>
                            </div>
                            <div>
                                <h3>Машина</h3>
                                <select {...register('car')} autoFocus
                                        onChange={(e)=>handleChooseCar(e.target.value)}>
                                    <option value={''}>Выберите машину</option>
                                    {
                                        availibleCars?.map(car => (
                                            <option value={car.id} key={car.id}>{car.name}</option>
                                        ))
                                    }
                                </select>
                                <br />
                                <h3 className="form__message">
                                    {chooseCar}
                                </h3>
                            </div>
                            <br />
                            <div>
                                <h3>Начало</h3>

                                <input onChange={(e)=>{setNeedDriveDay('') ;setDriveDay(e.target.value)}} type="date" placeholder='дата'/>
                                <select onChange={e => setDriveTime(e.target.value)}>
                                    {
                                        startTime.map((t, idx) => (
                                            <option key={idx} value={'T'+t}>{t}</option>
                                        ))
                                    }


                                </select>
                                <br />
                                <h3 className="form__message">
                                    {needDriveDay}
                                </h3>
                                <br />
                                <h3 className="form__message">
                                    {timeErr}
                                </h3>

                            </div>
                            <div>
                                {/*<input className='recentOrders__addBtn' onClick={addEmployee} type="button" value="+ Добавить сотрудника"/> <br />*/}
                                <Button variant="contained" sx={{marginY: '15px'}} onClick={addEmployee} type="button"> Добавить сотрудника</Button>
                                <br />
                                {
                                    employees?.map(el =>(
                                        <span key={el.id} style={{marginRight:'20px'}}>
                        <input type="text"  onChange={(e) => {searchHandler(e);reductEmployee(el.id ,e.target.value); console.log(e.target);}} placeholder='имя' list='users'/>
                        <button className='recentOrders__addBtn-close inside'
                                onClick={() =>{setCorrectEmployee('') ;setEmployees(prev => prev.filter(item => item.hisId !== el.hisId))}}>X</button>
                     </span>

                                    ))
                                }
                                <h3 className="form__message">
                                    {correctEmployee}
                                </h3>
                                <datalist id='users'>
                                    {
                                        users?.map(user => (
                                            <option key={user.id} value={user.full_name}>{user.username}</option>
                                        ))
                                    }
                                </datalist>
                            </div>
                            <div>

                                {/*<h3><input className='recentOrders__addBtn' onClick={addPlace} type="button" value="+ Добавить точку"/></h3> <br />*/}
                                <Button variant="contained" sx={{marginY: '15px'}} onClick={addPlace} type="button"> Добавить точку</Button>
                                {
                                    places?.map((el, id) => (
                                        <div className='recentOrders__newPlace' key={el.id}>
                                            <br />
                                            <input onChange={(e) => reductPlace(el.id, e.target.value, el.time)} value={el.placeName} type="text" placeholder='название' />
                                            <select onChange={(e) => reductPlace(el.id, el.placeName, e.target.value)} value={el.time}>
                                                <option value="" disabled>длительность</option>
                                                <option value="30">30 мин</option>
                                                <option value="60">1 час</option>
                                                <option value="90">1ч. 30мин.</option>
                                                <option value="120">2 часа</option>
                                                <option value="150">2ч. 30мин.</option>
                                                <option value="180">3 часа</option>
                                            </select>
                                            <button className='recentOrders__addBtn-close' onClick={() => delPlace(el.id)}>X</button>
                                            {/* <img width={50} onClick={() => delPlace(el.id)} className='recentOrders__delBtn' src="https://img.icons8.com/dotty/80/null/full-trash.png"/> */}
                                        </div>
                                    ))
                                }
                                {
                                    lastPlace?.placeName ?
                                        <>
                                            <br /><span>конечная точка </span><br /><br />

                                            <div className='recentOrders__newPlace' key={lastPlace.id}>
                                                <br />
                                                <input onChange={(e) => setLastPlace({...lastPlace,placeName: e.target.value})} value={lastPlace.placeName} type="text" placeholder='название' />
                                                <select onChange={(e) => setLastPlace({...lastPlace, time: e.target.value})} value={lastPlace.time}>
                                                    <option value="" disabled>длительность</option>
                                                    <option value="30">30 мин</option>
                                                    <option value="60">1 час</option>
                                                    <option value="90">1ч. 30мин.</option>
                                                    <option value="120">2 часа</option>
                                                    <option value="150">2ч. 30мин.</option>
                                                    <option value="180">3 часа</option>
                                                </select>
                                                <button className='recentOrders__addBtn-close' onClick={() =>  setLastPlace({})}>X</button>
                                                {/* <img width={50} onClick={() => prompt('вы хотите убрать конечный пункт?', '')&& setLastPlace({})} className='recentOrders__delBtn' src="https://img.icons8.com/dotty/80/null/full-trash.png"/> */}
                                            </div>
                                        </>
                                        : ''
                                }

                                <div className="form-group">
                                    <textarea {...register('comment')}
                                              className="form-control" id="exampleFormControlTextarea1" rows="4"
                                              placeholder={'комментарии'} style={{width:'200px'}}></textarea>
                                </div>

                                <h3 className='form__message'>{correctPlace}</h3>
                                {/* <h3>{response}</h3> */}
                            </div>
                            <br />

                            <Box sx={{display:'flex', gap: '5px'}}>
                                <Button onClick={() => navigate('/')} variant="outlined" type="button">Отменить</Button>
                                <Button variant="contained" type="submit">Создать</Button>
                            </Box>
                        </div>
                        {showPeriod &&
                        <div style={{marginTop:'60px'}}>
                            <Schedule period={period} setPeriod={setPeriod} register={register} readOnly/>
                        </div>}
                    </div>

                    <br /> <br />
                    <div>
                        <div>Количество адресов: {places.concat(lastPlace).filter(p => p.placeName).length} </div>
                        <div>Длительность - {totalTime()}</div>
                    </div>

                </form>


        </div>
        
        
    );
};

export default BookingForm;