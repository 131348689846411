import {Outlet} from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import {useState} from "react";
import './style.css'

const PageWrapper = () => {
    const [toggle, setToggle] = useState(false)
    return(
        <>
            <div>
                <Navigation toggle={toggle} setToggle={setToggle}/>
                <div className='outlet'>
                    <Outlet />
                </div>
            </div>
        </>
    )

}

export default PageWrapper