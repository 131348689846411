// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlet{
    margin-left: 300px;
}

@media only screen and (max-width: 1263px) {
    .outlet{
        margin-left: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Layout/PageWrapper/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".outlet{\n    margin-left: 300px;\n}\n\n@media only screen and (max-width: 1263px) {\n    .outlet{\n        margin-left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
