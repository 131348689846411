import {startTime} from "../../helpers/helpers";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

const Schedule = ({period, setPeriod, register, readOnly=false}) =>{
    const handleAddPeriod = () =>{
        if (period?.length < 5) {
            const newPeriod = {
                id: period?.length,
                start_date_time: "",
                end_date_time: "",
            };
            setPeriod([...period, newPeriod]);
        }
    }

    const handleDeletePeriod = (index) =>{
        setPeriod(prev => prev.filter((item,i) => i !== index))
    }
    return(
        <div>
            <p className='form-label'>Период активности</p>
            <div className='schedule-row' style={{display:'flex', flexDirection: 'column' , gap:"10px", alignItems:'flex-start'}}>
                {period?.map((p, index) => (
                    <div className='schedule-row' key={p.id}>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <input
                                className='edit-car-input'
                                type='date'
                                placeholder='дата'
                                readOnly={readOnly}
                                name={`start_date[${index}]`}
                                {...register(`start_date[${index}]`)}
                                value={p?.start_date_time?.slice(0, 10)}
                                onChange={(e) =>
                                    setPeriod((prev) =>
                                        prev.map((period,i) =>
                                            period?.id === p.id
                                                ? { ...period, start_date_time: e.target.value }
                                                : period
                                        )
                                    )
                                }
                            />
                            <select
                                disabled={readOnly}
                                name={`period[${index}].start_time`}
                                value={p.start_date_time.slice(11,16)}
                                {...register(`period[${index}].start_time`)}
                                onChange={(e) =>
                                    setPeriod((prev) =>
                                        prev.map((period,i) =>
                                            period?.id === p.id
                                                ? {
                                                    ...period,
                                                    start_date_time:
                                                        period?.start_date_time.slice(0, 10) +
                                                        'T' +
                                                        e.target.value +'Z',
                                                }
                                                : period
                                        )
                                    )
                                }
                            >
                                {startTime.map((t, idx) => (
                                    <option key={idx} value={t}>
                                        {t}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <span>-</span>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <input
                                className='edit-car-input'
                                type='date'
                                placeholder='дата'
                                readOnly={readOnly}
                                name={`end_date[${index}]`}
                                {...register(`end_date[${index}]`)}
                                value={p.end_date_time.slice(0, 10)}
                                onChange={(e) =>
                                    setPeriod((prev) =>
                                        prev.map((period,i) =>
                                            period.id === p.id
                                                ? { ...period, end_date_time: e.target.value }
                                                : period
                                        )
                                    )
                                }
                            />
                            <select
                                disabled={readOnly}
                                value={p.end_date_time.slice(11, 16)}
                                {...register(`period[${index}].end_date_time`)}
                                name={`period[${index}].end_date_time`}
                                aria-readonly={readOnly}
                                onChange={(e) =>
                                    setPeriod((prev) =>
                                        prev.map((period,i) =>
                                            period.id === p.id
                                                ? {
                                                    ...period,
                                                    end_date_time:
                                                        period.end_date_time.slice(0, 10) +
                                                        'T' +
                                                        e.target.value + 'Z',
                                                }
                                                : period
                                        )
                                    )
                                }
                            >
                                {startTime.map((t, idx) => (
                                    <option key={idx} value={t}>
                                        {t}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {!readOnly &&
                            index > 0 &&
                            <button type={'button'} onClick={() => handleDeletePeriod(index)} style={{borderStyle:'none', background:'none', cursor:'pointer'}}><CloseIcon/></button>}
                    </div>
                ))}

                {!readOnly && <Button variant="outlined"
                         style={{display: 'flex', gap: '5px', alignItems: 'center', marginBottom: '10px'}}
                         onClick={handleAddPeriod}
                         disabled={period?.length === 5}
                         type={'button'}>Добавить расписание<AddIcon/></Button>}
            </div>
        </div>
    )
}

export default Schedule