import React, { useEffect } from 'react';
import {ALL_CARS, LOOK__MY_BOOKINGS, SHOW_BOOKING_DETAILS} from '../../api/index';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import DetailPopup from '../../Components/DetailPopup';
import { Link } from 'react-router-dom';
import TopBar from "../../Layout/TopHeader";

const MyBookings = () => {
    // const {availibleCars} = useSelector(s => s.cars)
    const [availibleCars, setAvailibleCars] = useState([])
    const [driveDay, setDriveDay] = useState('')
    const [myBookings, setMyBookings] = useState([])
    const [startBooks, setStartBooks] = useState('')
    const [endBooks, setEndBooks] = useState('')
    const [carBooks, setCarBooks] = useState('')
    const [carStatus, setCarStatus] = useState('')
    const [details, setDetails] = useState({})

    const showBookingDetails = (id) =>{
        SHOW_BOOKING_DETAILS(id)
        .then(({data} )=>{ setDetails(data.data)})
        .catch(err => console.log(err))
    }
    const getAllCars = () => {
        ALL_CARS()
            .then(res =>{
                // console.log(res.data.data);
                setAvailibleCars(res.data.data)
            })
            .catch(err => console.log(err))
    }
    const {user} = useSelector(state => state.auth)

    const changeMyTable = (car ='', start='', end='', status='') => {
        LOOK__MY_BOOKINGS(JSON.parse(localStorage.getItem('user'))?.user, car, start, end, status)
        .then(res =>{
            // console.log(res.data.data);
             setMyBookings(res.data.data)})
        .catch(err => console.log(err))
    }

    useEffect(() =>{
       changeMyTable()
        getAllCars()
    },[user])

    const returnDate = (date, notime) =>{
        const bookDate = new Date(date);
        let day = bookDate.getDate();
        let month = bookDate.getMonth() + 1;
        let year = bookDate.getFullYear();
        let hours = bookDate.getHours();
        let minutes = bookDate.getMinutes()== 0 ? bookDate.getMinutes()+'0':bookDate.getMinutes();
        
        let currentDate;
         !notime ? currentDate = [`${hours}:${minutes}  `] : currentDate = [`${day}.${month}.${year}`]
        return currentDate; // "17-6-2022"
    }


    return (
        <div>
            <div className='home home__myCars details'>
                {/* <h1 className='home__title'>Машины</h1> */}
              
                


                <div className="recentOrders">
                    <TopBar pageText={'Мои бронирования'}/>
                    <div className='cardHeader__selects'>
                        <div className='myBookings-select'>
                            {/*<span>от </span>*/}
                            <input onChange={(e)=>{setEndBooks(e.target.value); changeMyTable(carBooks,  e.target.value, endBooks, carStatus)}} type="date" placeholder='дата'/>
                            {/*до*/}-
                            <input onChange={(e)=>{setStartBooks(e.target.value); changeMyTable(carBooks, startBooks, e.target.value, carStatus)}} type="date" placeholder='дата'/>

                        </div>
                         <div className='myBookings-select'>
                             <select autoFocus onChange={e =>{setCarBooks(e.target.value ); changeMyTable(e.target.value, startBooks , endBooks, carStatus)}} defaultValue=''>
                                 {/* <option value="" disabled>Выберите машину</option> */}
                                 <option value="" >Все машины</option>
                                 {
                                     availibleCars?.map(car => (
                                         <option value={car.id} key={car.id}>{car.name}</option>
                                     ))
                                 }
                             </select>
                             <select onChange={e =>{ setCarStatus(e.target.value) ;changeMyTable(carBooks, startBooks , endBooks, e.target.value)}} defaultValue=''>
                                 <option value="">Все бронирования</option>
                                 <option value={true} >Активные </option>
                                 <option value={false} >Прошедшие </option>
                             </select>
                         </div>

                    </div>

                    <div style={{overflow:'auto'}}>
                        <table style={{width:'100%'}}>
                            <thead>
                            <tr>
                                <td>Машина</td>
                                <td>Время</td>
                                <td>Дата</td>
                                <td>Статус</td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                myBookings?.map(({car, id, organizer, start_date_time, end_date_time, is_active}) =>(
                                    <tr key={id}  onClick={() => showBookingDetails(id)}>
                                        <td><span className="status 'progress'">{availibleCars.find(o => o.id === car)?.name}</span></td>
                                        <td className='cardHeader__time'>{returnDate(start_date_time)[0]} - {returnDate(end_date_time)[0]} </td>
                                        <td>{returnDate(start_date_time, 'notime')[0]} </td>
                                        <td>{is_active ? 'Активный' : 'Прошедший'}</td>
                                        <td className='home__carsSetting-btns' >
                                            <Link to={'/editMyBooking/'+ id}>
                                                <img onClick={() => {}} className='recentOrders__eddit' width={30} src="https://img.icons8.com/ios/50/null/create-new.png"/>
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            }

                            </tbody>
                        </table>
                    </div>

                </div>

                {
                    details?.id && <>
                    <DetailPopup details={details}/>
                    <div className="detail__overlay" onClick={() => setDetails({})}/>
                    </>
                }

            </div>
           
        </div>
    );
};

export default MyBookings;