import React from 'react';

const DetailPopup = ({details}) => {
    return (
        <div className='detail__popup'>

            <div>
                <h3>{details.car?.name}</h3>
                {/* <p>{details.organizer.full_name}</p> */}
                <span className='detail__popup-time'>
                    <img width={22} src="https://img.icons8.com/windows/32/000000/time-span.png"/>
                    {details.start_date_time.slice(11,16)} - {details.end_date_time.slice(11,16)}
                    <br/><br /><p>{new Date(details.start_date_time).toLocaleDateString('ru')}</p>
                </span> <br />
                <h3>Адреса</h3> <br />
                <ul>
                    {
                        details?.addresses?.map((d, i)=>(
                            <li key={i}>{i+1} {d?.name}</li>
                        ))
                    }
                </ul>
            </div>

            <div>
                <h3>Комментарии</h3> <br />
                <div style={{marginBottom:'10px'}}>{ details?.comment || '-'}</div>
                <h3>Сотрудники</h3> <br />
                {
                    details?.employees?.map((d,i)=>(
                        <li key={i}>{d?.full_name || '-'}</li>
                    ))
                }
            </div>
        </div>
    );
};

export default DetailPopup;