// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Layout/Navigation/navigation.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./pages/Main/main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./pages/carTable/carTable.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  color: inherit;
  text-decoration: none;
}

a{
  cursor: pointer;
  
}
:root{
  --blue: #287BFF;
  /* --blue: #0e1a2d; */
  --grey:#f5f5f5;
  --black: #222222;
  
  --ionicon-stroke-width: 32px;
  --ion-color-primary: #0fa;
  --ion-color-secondary: #fa0;
  --ion-color-tertiary: #f4a942;
  --ion-color-success: #10dc60;
  --ion-color-warning: #ffce00;
  --ion-color-danger: #f14141;
  --ion-color-light: #f4f5f8;
  --ion-color-medium: #989aa2;
  --ion-color-dark: #222428;
}

.container{
  position: relative;
  width: 100%;
}
body{
  min-height: 100vh;
}
select option{
  font-size: 15px;
  line-height: 28px;
  padding: 16px ;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,eAAe;;AAEjB;AACA;EACE,eAAe;EACf,qBAAqB;EACrB,cAAc;EACd,gBAAgB;;EAEhB,4BAA4B;EAC5B,yBAAyB;EACzB,2BAA2B;EAC3B,6BAA6B;EAC7B,4BAA4B;EAC5B,4BAA4B;EAC5B,2BAA2B;EAC3B,0BAA0B;EAC1B,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,YAAY;AACd","sourcesContent":["*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  list-style: none;\n  color: inherit;\n  text-decoration: none;\n}\n\na{\n  cursor: pointer;\n  \n}\n:root{\n  --blue: #287BFF;\n  /* --blue: #0e1a2d; */\n  --grey:#f5f5f5;\n  --black: #222222;\n  \n  --ionicon-stroke-width: 32px;\n  --ion-color-primary: #0fa;\n  --ion-color-secondary: #fa0;\n  --ion-color-tertiary: #f4a942;\n  --ion-color-success: #10dc60;\n  --ion-color-warning: #ffce00;\n  --ion-color-danger: #f14141;\n  --ion-color-light: #f4f5f8;\n  --ion-color-medium: #989aa2;\n  --ion-color-dark: #222428;\n}\n\n.container{\n  position: relative;\n  width: 100%;\n}\nbody{\n  min-height: 100vh;\n}\nselect option{\n  font-size: 15px;\n  line-height: 28px;\n  padding: 16px ;\n  height: 16px;\n}\n\n\n\n@import 'Layout/Navigation/navigation.css';\n@import './pages/Main/main.css';\n@import './pages//carTable/carTable.css';"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
