import { useEffect, useState } from 'react';
import './App.css';
import Navigation from './Layout/Navigation/Navigation';
import {getStorageData} from './helpers/helpers';
import {useSelector, useDispatch} from 'react-redux'
import {autoLogin, refreshToken, sighnInFatal, sighnInSuccess} from './redux/reducers/authSlice';
import SignIn from './pages/SignIn/SignIn';
import {Navigate, Route, Router, Routes, useLocation, useNavigate} from 'react-router-dom';
import Main from './pages/Main/Main';
import BookingForm from './pages/BookingForm/BookingForm';
import {fetchActiveCars} from './redux/reducers/carSlise';
import CarTable from './pages/carTable/CarTable';
import MyBookings from './pages/MyBookings/MyBookings';
import ReductMyBookings from './pages/ReductMyBookings/ReductMyBookings';
import Login from "./pages/Login";
import PageWrapper from "./Layout/PageWrapper";
import EditAddCar from "./pages/EditCar/EditAddCar";



function App() {
  const auth = useSelector(s => s.auth)
  const dispatch = useDispatch()
  const [toggle, setToggle] = useState(false)

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = location.search.slice(7,location.search.length);
  const navigate = useNavigate()
  const data = {service: 'car_booking', token};
  const access = getStorageData("token");
  const userData = getStorageData("user");

  useEffect(() => {
    if (token) {
      dispatch(autoLogin(data));
      navigate("/");
    }
  }, [token]);

  const { user } = useSelector((state) => state.auth);
  const isAdmin = user && user.isSuperUser;


  useEffect(() => {
    if (!auth.isAuthorised) {
      if (access && userData) {
        dispatch(sighnInSuccess({ user: userData }));
      } else {
        dispatch(sighnInFatal("Failed to login"));
      }
    } else if (!access) navigate('/login')
  }, [dispatch, auth.isAuthorised]);



  return (
    <div className="container">
      {/*<Navigation toggle={toggle} setToggle={setToggle}/>*/}
      <main className={`main ${toggle && 'active'}`}>
        <Routes>
          <Route element={<PageWrapper/>}>
            <Route path='/' element={<CarTable/>}/>
            <Route path='/bookingForm' element={<BookingForm/>}/>
            <Route path='/editMyBooking/:id' element={<ReductMyBookings/>}/>
            <Route path='/bookings' element={<MyBookings/>}/>
            <Route path="/cars" element={isAdmin ? <Main /> : <Navigate to="/" />} />
            <Route path="/edit-car/:id" element={isAdmin ? <EditAddCar /> : <Navigate to="/" />} />
            <Route path="/add-car" element={isAdmin ? <EditAddCar /> : <Navigate to="/" />} />
          </Route>
          {/*<Route path='/*' element={<SignIn/>}/>*/}
        </Routes>
        <Routes>
          <Route path="/login" element={<Login/>} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
