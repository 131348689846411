import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {LOOK_CAR_BOOKING} from "../../api";
import {fetchActiveCars} from "./carSlise";

// Create async thunk for fetching car bookings
export const fetchCarBookings = createAsyncThunk(
    'carBookings/fetchCarBookings',
    async (params, { rejectWithValue }) => {
        try {
            const response = await LOOK_CAR_BOOKING(params.id, params.is_active, params.start_date_time, params.end_date_time);
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Create car bookings slice
export const carBookingsSlice = createSlice({
    name: 'carBookings',
    initialState: {
        bookingData: [],
        loading: false,
        error: null,
        carId: null,
        isActive: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle pending state while fetching car bookings
            .addCase(fetchCarBookings.pending, (state) => {
                state.loading = true;
            })
            // Handle fulfilled state when car bookings are fetched successfully
            .addCase(fetchCarBookings.fulfilled, (state, action) => {
                if (state.carId === action.meta.arg.id &&
                    state.isActive === action.meta.arg.is_active &&
                    state.start_date_time === action.meta.arg.start_date_time &&
                    state.end_date_time === action.meta.arg.end_date_time) {
                    // Car ID and active state are the same as previous call, do not update date state
                    state.loading = false;
                    state.error = null;
                } else {
                    // Car ID and active state are different, update date state
                    state.loading = false;
                    state.bookingData = action.payload;
                    state.error = null;
                    state.carId = action.meta.arg.id;
                    state.isActive = action.meta.arg.is_active;
                    state.start_date_time = action.meta.arg.start_date_time;
                    state.end_date_time = action.meta.arg.end_date_time
                }
            })
            // Handle rejected state when there's an error while fetching car bookings
            .addCase(fetchCarBookings.rejected, (state, action) => {
                state.loading = false;
                state.bookingData = [];
                state.error = action.payload;
                state.carId = null;
                state.isActive = null;
            });
    },
});

// Export car bookings actions and reducer
export const { } = carBookingsSlice.actions;
export const carBookingsReducer = carBookingsSlice.reducer;
