// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 60px;
    /*margin-top: 15px;*/
    /*margin-bottom: 15px;*/
}
.user-name-btn{
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid grey;
}

@media only screen and (max-width: 1243px) {
    .user-name-btn{
        display: none;
    }
    .cardHeader{
        height: 45px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Layout/TopHeader/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,YAAY;IACZ,oBAAoB;IACpB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".cardHeader{\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    height: 60px;\n    /*margin-top: 15px;*/\n    /*margin-bottom: 15px;*/\n}\n.user-name-btn{\n    display: flex;\n    gap: 5px;\n    align-items: center;\n    padding: 8px;\n    border-radius: 5px;\n    cursor: pointer;\n    border: 1px solid grey;\n}\n\n@media only screen and (max-width: 1243px) {\n    .user-name-btn{\n        display: none;\n    }\n    .cardHeader{\n        height: 45px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
