import DetailPopup from "../../Components/DetailPopup";
import {Box} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import  './style.css'

const TopBar = ({pageText}) =>{
    const {user} = useSelector(state => state.auth)
    const [isOpen, setOpen] = useState(false)
    const navigate = useNavigate()
    const handleLogout = () =>{
        localStorage.clear();
        navigate('/login')
    }

    const ref = React.useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isOpen])


    return (
        <div className="cardHeader" ref={ref}>
            <h2 style={{margin:'0'}}>{pageText}</h2>
            <Box>
                <div onClick={()=> setOpen(!isOpen)} className={'user-name-btn'}>
                    <span> {user.full_name}</span>
                    <AccountCircleIcon />
                </div>
                {isOpen &&
                <Box sx={{ boxShadow:2, padding:'10px', borderRadius:'5px', cursor:'pointer', marginTop:'3px'}} onClick={handleLogout}>Выйти</Box>}
            </Box>

        </div>
    )
}

export default TopBar
