import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ACTIVE_CARS} from "../../api";



const initialState = {
    availibleCars : [],
    response: '',
    timeErr: '',
    
}
export const fetchActiveCars = createAsyncThunk(
    'cars/fetchActiveCars',
    async () => {
        try{
            const response = await ACTIVE_CARS();
            return response.data.data;
        } catch (e) {
            console.log('ERR', e)
        }

    }
);

export const carSlice = createSlice({
    name: 'cars',
    initialState,
    reducers: {
        showCurrentCarScedule: (state, action) => {
            
        },
        showResponse: (state, action) =>{
            state.response = action.payload
        },
        showTimeErr: (state, action) =>{
            state.timeErr = action.payload
        },
    },
    extraReducers: {
        [fetchActiveCars.fulfilled]: (state, action) => {
            state.availibleCars = action.payload;
        }
    }
})


export const {findActiveCars, showResponse, showTimeErr } = carSlice.actions;
export default carSlice.reducer;